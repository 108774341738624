import { BrowserRouter as Router, useLocation } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./shared/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HelmetProvider, Helmet } from "react-helmet-async";
import store from "./store";
import { useEffect } from "react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const GoogleTagManager: React.FC = () => {
  useEffect(() => {
    // Inject the Google Tag Manager script
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=G-GET8CXLEHG`;

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-GET8CXLEHG');
    `;

    document.head.appendChild(script1);
    document.head.appendChild(script2);

    return () => {
      // Cleanup function to remove the scripts if the component is unmounted
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return null; // This component doesn't render any UI
};
const HubspotTracking: React.FC = () => {
  useEffect(() => {
    // Create the script tag for HubSpot tracking
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js.hs-scripts.com/46304418.js"; // Replace with your HubSpot ID

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup function to remove the script if the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component doesn't render any UI
};


const LinkedInTracking: React.FC = () => {
  useEffect(() => {
    // Set LinkedIn partner ID
    (window as any)._linkedin_partner_id = "6816660";
    (window as any)._linkedin_data_partner_ids = (window as any)._linkedin_data_partner_ids || [];
    (window as any)._linkedin_data_partner_ids.push((window as any)._linkedin_partner_id);

    // Create and append the main tracking script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    `;
    document.body.appendChild(script);

    // Create and append the noscript pixel
    const noscript = document.createElement("noscript");
    const img = document.createElement("img");
    img.height = 1;
    img.width = 1;
    img.style.display = "none";
    img.alt = "";
    img.src = "https://px.ads.linkedin.com/collect/?pid=6816660&fmt=gif";
    noscript.appendChild(img);
    document.body.appendChild(noscript);

    return () => {
      // Cleanup
      document.body.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  return null;
};

const ZoomInfoTracking: React.FC = () => {
  useEffect(() => {
    // Decrypt and set the window property correctly
    const windowKey: string = (function (_dbt: string[], _8r: number) {
      let _m8pCl = "";
      for (let _swTJND = 0; _swTJND < _dbt.length; _swTJND++) {
        let _I00K = _dbt[_swTJND].charCodeAt(0); // Fix: Pass 0 as index
        _I00K -= _8r;
        _I00K += 61;
        _I00K %= 94;
        _I00K += 33;
        _m8pCl += String.fromCharCode(_I00K);
      }
      return _m8pCl;
    })(atob("cF9mKicie3ksYXsx").split(""), 22);

    // Set the value for ZoomInfo key in window
    (window as any)[windowKey] = "cecf37cf451727817124"; // Fix: cast window to any

    // Create the ZoomInfo tracking script
    const zi = document.createElement("script");
    zi.type = "text/javascript";
    zi.async = true;
    zi.src = (function (_vTa: string[], _I9: number) {
      let _3jmWV = "";
      for (let _nMRPZm = 0; _nMRPZm < _vTa.length; _nMRPZm++) {
        let _5kRV = _vTa[_nMRPZm].charCodeAt(0); // Fix: Pass 0 as index
        _5kRV -= _I9;
        _5kRV += 61;
        _5kRV %= 94;
        _5kRV += 33;
        _3jmWV += String.fromCharCode(_5kRV);
      }
      return _3jmWV;
    })(atob("Lzs7NzpfVFQxOlNBMFI6KjkwNzs6Uyo2NFRBMFI7KC5TMTo=").split(""), 37);

    // Append script once the page is fully loaded
    if (document.readyState === "complete") {
      document.body.appendChild(zi);
    } else {
      window.addEventListener("load", () => {
        document.body.appendChild(zi);
      });
    }

    return () => {
      // Cleanup the script if the component is unmounted
      document.body.removeChild(zi);
    };
  }, []);

  return null;
};
// Track page view on route change for GTM (Google Analytics)
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-GET8CXLEHG", {
        page_path: location.pathname,
      });
    }
  }, [location]);
};

const AppWithTracking = () => {
  usePageTracking(); // Track page views for every route change
  return <App />;
};

root.render(
  <GoogleOAuthProvider clientId="1234">
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <HelmetProvider>
          <Helmet>
            {/* Yandex tracking code */}
            <meta name="verification" content="8dc43635ada2c777" />
          </Helmet>
          <Router>
            <ZoomInfoTracking />
            <GoogleTagManager />
            <HubspotTracking />
            <AppWithTracking />
            <LinkedInTracking />
          </Router>
        </HelmetProvider>
      </DndProvider>
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
