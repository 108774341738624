import { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login, logout } from "auth/authActions";
import { useNavigate, useLocation } from "react-router-dom";
import QuickPitch from "components/QuickPitch/QuickPitch";
import SignupForm1 from "components/Signup/SignupForm1";
import SignupForm2 from "components/Signup/SignupForm2";
import { startLoading, endLoading } from "state/appActions";
import { signup, getUser, getRegistrationUser } from "api/helpers";
import { GetUser, QuickOrg } from "types";
import { setUserData } from "auth/authActions";
import { SignupFormData } from "types";
import axios from "axios";
import {
  SignupWrapper,
  FormWrapper,
  ContentWrapper,
  FormPlaceHolder,
} from "./Signup.styled";
let unescape = window.unescape || window.decodeURI;

interface SignupProps {}

const Signup: FC<SignupProps> = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<SignupFormData>({
    email: "",
    first_name: "",
    organization_name: "",
    last_name: "",
    organization_id: "",
    role: "",
    industry: "",
    free_trial: false,
    password: "",
    default_method_name: "MEDDPICC",
    passwordConfirm: "",
    linkedin_url: "",
    termsChecked: false,
    completed_intro_tour: false,
  });
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [org, setOrg] = useState<QuickOrg>({
    name: "",
    id: "",
  });
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get("redirect");
  const token = queryParams.get("token");
  const invite = queryParams.get("invite");
  const admin = queryParams.get("a");
  const freeTrial = queryParams.get("ft");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const getRegUser = async (token: string) => {
      let response = await getRegistrationUser(token);
      setOrg({
        name: response.organization_name,
        id: response.organization_id,
      });
      setFormData({
        ...formData,
        email: response.email,
        organization_id: response.organization_id,
      });
    };
    const queryParams = new URLSearchParams(location.search);
    if (token && invite) {
      getRegUser(token);
    }
    if (admin === "t") {
      setFormData({
        ...formData,
        role: "admin",
      });
    }
    // Skip the tour if the user is redirecting a specific shared deal page
    if (redirect && redirect.includes("app/deals/")) {
      setFormData({
        ...formData,
        completed_intro_tour: true,
      });
    }
  }, []);

  // Handles Registration
  const handleSignup = async () => {
    setError("");
    if (!formData.termsChecked) {
      setError("Must approve terms and conditions before signing up.");
      return;
    }
    if (formData.password !== formData.passwordConfirm) {
      setError("Passwords must match before signing up.");
      return;
    }
    if (
      !formData.role ||
      !formData.linkedin_url ||
      (!formData.organization_name && !formData.organization_id)
    ) {
      setError("Please fill out all fields.");
      return;
    }
    dispatch(startLoading());
    try {
      const queryParams = new URLSearchParams(location.search);
      const admin = queryParams.get("a");
      let newData = formData;
      if (admin === "t") {
        newData.role = "admin";
      }
      if (freeTrial === "t") {
        newData.free_trial = true;
      }
      let response = await signup(newData);
      if (axios.isAxiosError(response)) {
        // Handle AxiosError
        setError(response?.response?.data?.message);
      } else if (response?.status === "success") {
        dispatch(login());
        let data: GetUser = await getUser(dispatch);
        dispatch(setUserData(data.user));
        if (redirect) {
          navigate(redirect);
        } else {
          navigate("/app/get-started");
        }
      } else {
        setError(response?.message);
      }
    } catch (e: any) {
      localStorage.removeItem("userToken");
      console.log(e);
      setError(
        "An error occurred registering you as a user. Please refresh the page and try again."
      );
    }
    dispatch(endLoading());
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    // Create a copy of the state object and update the specific field
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const selectChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    // Create a copy of the state object and update the specific field
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <SignupWrapper>
      <div className="blur"></div>
      <ContentWrapper></ContentWrapper>
      <QuickPitch signup />
      {/* <FormPlaceHolder /> */}
      <FormWrapper>
        {(() => {
          switch (step) {
            case 1:
              return (
                <SignupForm1
                  step={step}
                  setStep={setStep}
                  formData={formData}
                  handleInputChange={handleInputChange}
                  org={org}
                />
              );
            case 2:
              return (
                <SignupForm2
                  handleSignup={handleSignup}
                  step={step}
                  setStep={setStep}
                  formData={formData}
                  handleInputChange={handleInputChange}
                  selectChangeHandler={selectChangeHandler}
                />
              );
          }
        })()}
        {error && <p className="error">{error}</p>}
      </FormWrapper>
    </SignupWrapper>
  );
};

export default Signup;
