import styled from "styled-components"
import { colors } from "colors"

export const HeroSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;

  .flex-wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .vanta-logo {
    height: 120px;
    width: 120px;
    bottom: 0;
    right: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .gdpr-logo {
    height: 90px;
    width: 90px;
    margin-right: 15px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .ccpa-logo {
    height: 100px;
    width: 100px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  mark {
    color: ${colors.mainGreen};
  }
  .section-left {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 1 50%;
    margin-bottom: 100px;
    margin-top: 50px;
    padding-right: 10px;
    justify-content: space-between;
    @media (max-width: 906px) {
      padding: 100px 25px 0 25px;
      h1 {
        font-size: 45px;
      }
      button {
        width: 100%;
      }
    }
    h1 {
      margin-bottom: 14px;
    }
    h4 {
      margin: 35px 0;
      color: ${colors.lightGray};
      max-width: 500px;
    }
    svg {
      margin-top: 50px;
    }
  }
  .section-right {
    display: flex;
    flex-direction: column;
    /* flex: 0 1 50%; */
    width: 100%;
    justify-content: space-between;
    padding-left: 10px;
    position: relative;
    margin-left: 0%;
    div {
      img {
        max-width: 1500px;
        width: inherit;
      }
      .star-box {
        position: absolute;
        width: 290px;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: -0.01em;
        text-align: left;
        padding: 8px;
        padding-left: 43px;
        padding-top: 15px;

        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        background: radial-gradient(
            100% 100% at 0% 0%,
            rgba(41, 45, 50, 1) 0%,
            rgba(41, 45, 50, 0.23) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        &::before {
          content: "";
          position: absolute;
          inset: 0;
          border-radius: 5px;
          padding: 2px; /* control the border thickness */
          background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
          pointer-events: none;
        }
        .svg-wrapper {
          position: absolute;
          top: -39px;
          left: -57px;
          height: 60px;
          width: 60px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      .box2 {
        top: unset !important;
        left: 50%;
        bottom: -75px;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media (min-width: 1427px) {
    flex-direction: row;
    img {
      max-width: 815px;
    }
    .section-right {
      height: 50%;
      div {
        .star-box {
          right: -50px;
          top: -40px;
          transform: unset;
        }
        .box2 {
          left: -50px;
          bottom: -60px;
        }
      }
    }
    .section-left {
      min-width: 680px;
      svg {
        margin: unset;
      }
    }
  }
`
