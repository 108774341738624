import { FC, useState } from "react";
import {
  LoginInput,
  LoginLabel,
  SquareButton,
} from "shared/shared_styled_comps/forms.styled";
import { QuickOrg, SignupFormData } from "types";
import { SignupForm, InnerFormWrapper } from "./Signup.styled";
import { OAuthWrappr, OAuthLink } from "../Login/Login.styled";
import Google from "static/svgs/Google";
import Zoom from "static/svgs/Zoom";
import { getGoogleLoginURL, getGoogleURL, getZoomURL } from "api/helpers";

const restrictedDomains = [
  "saleshood.com",
  "paragon.ai",
  "attention.tech",
  "gong.io",
  "wonderway.io",
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "apple.com",
];

interface SignupForm1Props {
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  formData: SignupFormData;
  setStep: Function;
  step: number;
  org?: QuickOrg;
}

const SignupForm1: FC<SignupForm1Props> = ({
  formData,
  handleInputChange,
  setStep,
  step,
  org,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [passmatch, setPassmatch] = useState(true);

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    isMainPassword: boolean
  ) => {
    if (isMainPassword) {
      if (event.target.value.length < 8) {
        setError("Passwords must be atleast 8 characters.");
        return;
      }
      if (event.target.value != formData.passwordConfirm) {
        setPassmatch(false);
        setError("Passwords must match.");
      } else {
        setPassmatch(true);
        setError("");
      }
    } else {
      if (event.target.value != formData.password) {
        setPassmatch(false);
        setError("Passwords must match.");
      } else {
        setPassmatch(true);
        setError("");
      }
    }
  };

  const handleOAuthLogin = async (type: string) => {
    setLoading(true);
    let response;
    switch (type) {
      case "google":
        if (org?.id) {
          response = await getGoogleLoginURL(org.id);
        } else {
          response = await getGoogleLoginURL();
        }
        break;
      case "zoom":
        response = await getZoomURL();
        break;
    }
    if (response) {
      window.location.assign(response.redirect_url);
    } else {
      setError(
        `Error loading the ${type} authenticator. Please refresh and try again`
      );
    }
  };

  const handleNext = (e: any) => {
    e.preventDefault();
    if (formData.password.length < 8) {
      setError("Passwords must be atleast 8 characters.");
      return;
    }
    if (!passmatch) {
      setError("Passwords must match.");
      return;
    }
    if (validateEmail()) {
      if (
        error === "" &&
        formData.password &&
        formData.first_name &&
        formData.last_name
      ) {
        setStep(2);
        window.scrollTo(0, 0);
      } else {
        setError("Please complete all form data.");
      }
    }
  };

  const validateEmail = () => {
    // Regular expression for validating email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(formData.email)) {
      setError("Please enter a valid email.");
      return false;
    } else {
      const emailDomain = formData.email.split("@")[1];
      if (restrictedDomains.includes(emailDomain)) {
        setError("Please use a valid company email.");
        return false;
      } else {
        setError("");
        return true;
      }
    }
  };

  return (
    <InnerFormWrapper>
      <h1>Sign up {org?.name && `with ${org.name}`}</h1>
      <h5></h5>
      <>
        <OAuthWrappr>
          <OAuthLink onClick={() => handleOAuthLogin("google")}>
            <Google />
            Log in with Google
          </OAuthLink>
          {/* <OAuthLink onClick={() => handleOAuthLogin("zoom")}>
              <Zoom />
              Log in with Zoom
            </OAuthLink> */}
        </OAuthWrappr>
        <div className="horizontal-divider">
          <div className="line"></div>
          <div className="or-text">or</div>
          <div className="line"></div>
        </div>
      </>
      <SignupForm>
        <div className="name-wrapper">
          <div className="input-wrapper">
            <LoginLabel>
              First name
              <LoginInput
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
              />
            </LoginLabel>
          </div>
          <div className="input-wrapper">
            <LoginLabel>
              Last name
              <LoginInput
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
              />
            </LoginLabel>
          </div>
        </div>
        <div className="email-wrapper">
          <LoginLabel>
            Company Email
            <LoginInput
              disabled={org?.id !== ""}
              type="text"
              name="email"
              value={formData.email}
              onBlur={validateEmail}
              onChange={handleInputChange}
            />
          </LoginLabel>
        </div>
        <LoginLabel>
          Password
          <LoginInput
            type="password"
            name="password"
            value={formData.password}
            onBlur={(e) => handleBlur(e, true)}
            onChange={handleInputChange}
          />
        </LoginLabel>
        <LoginLabel>
          Confirm Password
          <LoginInput
            type="password"
            name="passwordConfirm"
            value={formData.passwordConfirm}
            onBlur={(e) => handleBlur(e, false)}
            onChange={handleInputChange}
          />
        </LoginLabel>
        <div className="button-text-wrapper">
          <p>Step {step}/2</p>
          <div className="button-wrapper">
            <SquareButton onClick={handleNext}>Next</SquareButton>
          </div>
        </div>
        {error && <p className="error">{error}</p>}
      </SignupForm>
      <div className="logos-wrapper"></div>
    </InnerFormWrapper>
  );
};

export default SignupForm1;
