import styled from 'styled-components';

export const VideoTourWrapper = styled.div`
  position: fixed; // Changed to fixed
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001000; // Ensure it's above other content
  padding: 20px;
`;

export const VideoOverlay = styled.div`
  position: fixed; // Changed to fixed
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); // Darkened the overlay
  backdrop-filter: blur(8px);
  pointer-events: none;
  z-index: 1000000; // Just below the video container
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1100px;
  padding-top: 50%; /* 16:9 Aspect Ratio */
  z-index: 10000001; // Ensure video is above overlay
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;