import React, { FC, useState } from "react";
import {
  VideoTourWrapper,
  VideoOverlay,
  VideoContainer,
} from "./VideoTour.styled";
import { useDispatch, useSelector } from "react-redux";
import { closeVideoTour } from "state/appActions";
import { putUser } from "api/helpers";

interface VideoTourProps {}
const VideoTour: FC<VideoTourProps> = () => {
  const dispatch = useDispatch();
  const videoTour = useSelector((state: any) => state.app.videoTour);

  React.useEffect(() => {
    // Disable scrolling when component mounts
    document.body.style.overflow = "hidden";

    // Re-enable scrolling on cleanup
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleClose = async () => {
    document.body.style.overflow = "unset";
    dispatch(closeVideoTour());
    await putUser({ completed_intro_tour: true });
  };

  if (!videoTour) return null;

  return (
    <VideoTourWrapper>
      <VideoOverlay />
      <button
        onClick={() => handleClose()}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          zIndex: 10000002,
          background: "white",
          border: "none",
          borderRadius: "50%",
          width: "32px",
          height: "32px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px"
        }}
      >
        ×
      </button>
      <VideoContainer>
        <iframe
          src="https://www.loom.com/embed/066f7d1a569546ccacc755a908bfb312?sid=c2b24cb0-b626-4555-bcc8-a97b8ad91aa0"
          allowFullScreen
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            border: "0", // Replace frameBorder with this
          }}
        />
      </VideoContainer>
    </VideoTourWrapper>
  );
};

export default VideoTour;
