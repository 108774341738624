import {
  FC,
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import {
  NavigationWrapper,
  AppNavigationWrapper,
  User,
  DropDownMenu,
  ArrowWrapper,
  Dropdown,
  CircularProgress,
} from "./Navigation.styled";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "auth/authActions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Arrow from "static/svgs/Arrow";
import LogoWithText from "static/svgs/LogoWithText";
import { GreenButton } from "shared/shared_styled_comps/components.styled";
import Keyboard from "static/svgs/Keyboard";
import Dollar from "static/svgs/Dollar";
import Settings from "static/svgs/Settings";
import Chalice from "static/svgs/Chalice";
import { Squash as Hamburger, Squash } from "hamburger-react";
import Notifications from "components/Notifications/Notifications";
import { setVideoTour } from "state/appActions";

// const nav_excluded_paths = ["login", "signup"]
const nav_excluded_paths = [];

interface NavigationProps {
  setTourStep: Dispatch<SetStateAction<number>>;
  setTourIsOpen: Dispatch<SetStateAction<boolean>>;
}

const icons: any = {
  Dashboard: <Keyboard />,
  Deal: <Dollar />,
  Deals: <Dollar />,
  Coaching: <Chalice />,
  Settings: <Settings />,
};

const Navigation: FC<NavigationProps> = ({ setTourIsOpen, setTourStep }) => {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const user = useSelector((state: any) => state.auth.user);
  const darkMode = useSelector((state: any) => state.app.darkMode);
  const [showDropDown, setShowDropDown] = useState(false);
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number | null>(null);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const userRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = location;
  const introCompletion: number = useSelector(
    (state: any) => state.app.introCompletion
  );
  const pageTitle: string = useSelector((state: any) => state.app.pageTitle);

  // Clears authenticated and local storage
  const handleLogout = async () => {
    setShowDropDown(false);
    dispatch(logout());
    navigate("/login");
  };

  const handleImageError = () => {};

  useEffect(() => {
    if (openMobileNav) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [openMobileNav]);

  useEffect(() => {
    if (openMobileNav) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [openMobileNav]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        menuRef &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        userRef.current &&
        !userRef.current.contains(event.target as Node)
      ) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("click", (event) => handleClickOutside(event));

    return () => {
      document.removeEventListener("click", (event) =>
        handleClickOutside(event)
      );
    };
  }, []);

  const handleGiveFeedback = () => {
    const email = "feedback@leadoff.ai";
    const subject = "Feedback for Leadoff.ai - ";
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}`;
  };

  useEffect(() => {
    setShowDropDown(false);
    setOpenMobileNav(false);
  }, [pathname]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== null && window.innerWidth !== undefined) {
        setWindowWidth(window?.innerWidth);
      }
    };

    // Initial width calculation
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isAuthenticated]);

  const handleTourNavigate = (href: string, tourStep: number) => {
    if (!user?.completed_intro_tour && setTourStep && setTourIsOpen) {
      setShowDropDown(false);
      setTourIsOpen(false);
      setTourStep(tourStep);
      navigate(href);
    }
  };

  // if (!nav_excluded_paths.some((substring) => pathname.includes(substring))) {
  if (!pathname.includes("/app")) {
    return (
      <NavigationWrapper>
        <div className="nav-inner-wrapper">
          <div className="logo-wrapper">
            <Link to="/">
              <LogoWithText />
            </Link>
          </div>
          <div
            className={`${openMobileNav ? "nav-wrapper open" : "nav-wrapper"}`}
          >
            <Link to="/winning-behaviors" className="long-link">
              <h3>Winning Behaviors</h3>
            </Link>
            <Link to="/sales-automation" className="long-link">
              <h3>Sales Automation</h3>
            </Link>
            <Link
              to=""
              style={{ position: "relative" }}
              onMouseEnter={() => setIsDropdownVisible(true)}
              onMouseLeave={() => setIsDropdownVisible(false)}
            >
              <h3>
                Solutions <span className="mobile-only flex-span">&#8964;</span>
              </h3>
              <Dropdown isVisible={isDropdownVisible}>
                <div className="flex-wrapper">
                  <div className="link-wrapper">
                    <h3>
                      <Link to="/solutions-reps">Reps</Link>
                    </h3>
                    <Link className="no-mobile" to="/solutions-reps#section-0">
                      Research
                    </Link>
                    <Link className="no-mobile" to="/solutions-reps#section-1">
                      Summarization
                    </Link>
                    <Link className="no-mobile" to="/solutions-reps#section-2">
                      Action Items
                    </Link>
                    <Link className="no-mobile" to="/solutions-reps#section-3">
                      Coaching
                    </Link>
                    <Link className="no-mobile" to="/solutions-reps#section-4">
                      Resources
                    </Link>
                  </div>
                  <div className="link-wrapper">
                    <h3>
                      <Link to="/solutions-leaders">Leaders</Link>
                    </h3>
                    <Link
                      className="no-mobile"
                      to="/solutions-leaders#section-0"
                    >
                      Research
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-leaders#section-1"
                    >
                      Analysis
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-leaders#section-2"
                    >
                      Reviews
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-leaders#section-3"
                    >
                      Coaching
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-leaders#section-4"
                    >
                      Trends
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-leaders#section-5"
                    >
                      Resources
                    </Link>
                  </div>
                  <div className="link-wrapper">
                    <h3>
                      <Link to="/solutions-enablement">Enablement</Link>
                    </h3>
                    <Link
                      className="no-mobile"
                      to="/solutions-enablement#section-0"
                    >
                      Rep Coaching
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-enablement#section-1"
                    >
                      Enablement
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-enablement#section-2"
                    >
                      Tracking
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-enablement#section-3"
                    >
                      Impact
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-enablement#section-4"
                    >
                      Data-driven
                    </Link>
                    <Link
                      className="no-mobile"
                      to="/solutions-enablement#section-5"
                    >
                      Collaboration
                    </Link>
                  </div>
                </div>
              </Dropdown>
            </Link>
            <Link to="/faqs">
              <h3>FAQs</h3>
            </Link>
            <Link to="/blog">
              <h3>Blog</h3>
            </Link>
            <Link to="/team">
              <h3>Team</h3>
            </Link>
            {/* <Link to="/login">
              <h3>Pricing</h3>
            </Link> */}
            <hr />
            {isAuthenticated ? (
              <>
                <Link to={`/app/deals`}>
                  <h3>Dashboard</h3>
                </Link>
                <Link className="free-trial" to="/app/deals">
                  <GreenButton
                    width={
                      windowWidth && windowWidth < 990 ? `fit-content` : "145px"
                    }
                    height={windowWidth && windowWidth < 990 ? 70 : 30}
                    fontSize={windowWidth && windowWidth < 990 ? 40 : 13}
                    paddingright={windowWidth && windowWidth < 990 ? 30 : 0}
                    paddingleft={windowWidth && windowWidth < 990 ? 30 : 0}
                  >
                    My Deals
                  </GreenButton>
                </Link>
              </>
            ) : (
              <>
                <Link to={`/login${location.search}`}>
                  <h3>Login</h3>
                </Link>
                <Link className="free-trial" to="/contact-us">
                  <GreenButton
                    width={
                      windowWidth && windowWidth < 990 ? `fit-content` : "145px"
                    }
                    height={windowWidth && windowWidth < 990 ? 70 : 30}
                    fontSize={windowWidth && windowWidth < 990 ? 40 : 13}
                    paddingright={windowWidth && windowWidth < 990 ? 30 : 0}
                    paddingleft={windowWidth && windowWidth < 990 ? 30 : 0}
                  >
                    Contact Sales
                  </GreenButton>
                </Link>
              </>
            )}
          </div>
          <div className="mobile-nav-button">
            <Squash toggled={openMobileNav} toggle={setOpenMobileNav} />
          </div>
        </div>
      </NavigationWrapper>
    );
  } else {
    return (
      <AppNavigationWrapper>
        <div className="title-wrapper">
          {pageTitle && icons[pageTitle.split(" ")[0]]}
          <h2 className="title">{pageTitle}</h2>
        </div>
        <div className="right-wrapper">
          {user.intro_completion !== 100 && <CircularProgress
            onClick={() => navigate("/app/get-started")}
            percent={Math.max(Math.round(introCompletion), user.intro_completion || 0)}
          >
            <div className="circle-progress">
              <div className="circle-progress-left" />
              <div className="circle-progress-right" />
            </div>
            <span>{Math.max(Math.round(introCompletion), user.intro_completion || 0)}%</span>
          </CircularProgress>}
          {/* <button onClick={() => navigate(-1)}>{"<-"}</button> */}
          <User
            className="user-settings"
            onClick={() => setShowDropDown(true)}
            ref={userRef}
          >
            <Notifications />
            <div className="user-wrapper">
              <div className="pfp">
                {user.image_url ? (
                  <img src={user.image_url} onError={handleImageError} />
                ) : (
                  user.first_name && (
                    <div className="letter-wrapper">
                      {user.first_name[0].toUpperCase()}
                    </div>
                  )
                )}
              </div>
              <div className="text">
                <p className="sub-text">Welcome back!</p>
                <p>
                  {user.first_name}{" "}
                  {user && user.last_name
                    ? user.last_name[0].toUpperCase()
                    : ""}
                  .
                </p>
              </div>
              <ArrowWrapper showdropdown={showDropDown}>
                <Arrow />
              </ArrowWrapper>
            </div>
          </User>
        </div>
        {showDropDown && (
          <DropDownMenu ref={menuRef}>
            <h4>
              {user.first_name} {user.last_name}
            </h4>
            <p className="email">{user.email}</p>
            <hr className="first" />
            <h4>Settings</h4>

            <Link to="/app/settings/profile">
              <p className="link">My Profile</p>
            </Link>

            {user && !user?.completed_intro_tour ? (
              <a
                className="tour-link"
                onClick={() =>
                  handleTourNavigate("/app/settings/company-account", 20)
                }
              >
                Company Account
              </a>
            ) : (
              <Link to="/app/settings/company-account">
                <p className="link">Company Account</p>
              </Link>
            )}
            {user && !user?.completed_intro_tour ? (
              <a
                className="tour-link"
                onClick={() =>
                  handleTourNavigate("/app/settings/integrations", 18)
                }
              >
                Integrations
              </a>
            ) : (
              <Link to="/app/settings/integrations">
                <p className="link">Integrations</p>
              </Link>
            )}
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowDropDown(false);
                dispatch(setVideoTour());
              }}
              className="link"
            >
              Tutorial Video
            </p>
            {/* <Link to="/app/settings/billing">
              <p className="link">Billing</p>
            </Link> */}
            {/* <Link to="/app/settings/reports">
              <p className="link">My Reports</p>
            </Link> */}
            {/* <Link to="/app/settings/notifications">
              <p className="link">Notifications</p>
            </Link> */}

            {/* <Link to="/app/settings/coaching">
              <p className="link">Coaching Materials</p>
            </Link> */}
            {/* <Link to="/app/settings/integrations">
              <p className="link">Integrations</p>
            </Link> */}
            <hr />
            <Link to="/app/settings/company-account?invite=true">
              <button onClick={() => setShowDropDown(false)}>
                Refer Teammates
              </button>
            </Link>
            <button onClick={handleGiveFeedback}>Give Feedback</button>
            <button onClick={handleLogout}>Logout</button>
          </DropDownMenu>
        )}
      </AppNavigationWrapper>
    );
  }
  // }

  return <></>;
};

export default Navigation;
