import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading } from "state/appActions";
import { getDeals, deleteDeal, putDeal } from "api/helpers";
import moment from "moment";
import { Category, Priority, Stage } from "types";
import { ScoreBox } from "components/DealPage/DealPage.styled";
import { ScoreBox as TotalScoreBox } from "components/DealSummary/DealSummary.styled";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import {
  GreenButton,
  GradientButton,
  GradientText,
} from "shared/shared_styled_comps/components.styled";
import Paginate from "components/reusable/Paginate/Paginate";
import {
  Deal,
  DealsWrapper,
  DealsHeader,
  DealsTable,
  DealLoad,
  SearchWrapper,
  DealsLoadWrapper,
  ToggleButton,
  ToggleContainer,
  ToggleBackground,
} from "./DealsPage.styled";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import Filter from "static/svgs/Filter";
import { Deal as DealDetails, CategoryDefault } from "types";
import Trash from "static/svgs/Trash";
import Refresh from "static/svgs/Refresh";
import PriorityDropdown from "components/PriorityDropdown/PriorityDropdown";

interface DealsPageProps {
  tourStep: number;
  setTourStep: Dispatch<SetStateAction<number>>;
  setTourIsOpen: Dispatch<SetStateAction<boolean>>;
}

const defaultScores = [
  {
    category_name: "C",
    highest_score: 0,
  },
  {
    category_name: "M",
    highest_score: 0,
  },
  {
    category_name: "E",
    highest_score: 0,
  },
  {
    category_name: "D",
    highest_score: 0,
  },
  {
    category_name: "D",
    highest_score: 0,
  },
  {
    category_name: "P",
    highest_score: 0,
  },
  {
    category_name: "I",
    highest_score: 0,
  },
  {
    category_name: "C",
    highest_score: 0,
  },
  {
    category_name: "C",
    highest_score: 0,
  },
  {
    category_name: "V",
    highest_score: 0,
  },
];

const DealsPage: FC<DealsPageProps> = ({
  tourStep,
  setTourStep,
  setTourIsOpen,
}) => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const isLoading = useSelector((state: any) => state.app.isLoading);
  const [activeOption, setActiveOption] = useState<"my-deals" | "team-deals">(
    "my-deals"
  );

  const [data, setData] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [direction, setDirection] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDeals, setTotalDeals] = useState(1);
  const tipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [dealStages, setDealStages] = useState<{ [key: string]: string }>({});
  const [editingDealId, setEditingDealId] = useState<string | null>(null);
  const [editedDealName, setEditedDealName] = useState("");
  const stages = useSelector((state: any) => state.app.stages);

  const tableHeaders: string[] = [
    "Score",
    "Deal Name",
    ...(activeOption === "team-deals" ? ["Rep"] : []),
    "Last Call",
    "Stage",
    "Categories",
    "Priority",
  ];

  const fetchDealsData = async () => {
    dispatch(startLoading());
    let data = {
      sortBy: sortBy,
      sortOrder: direction ? "desc" : "asc",
      limit: 10,
      page: activePage,
      filterBy: "",
      searchBy: searchValue,
      orgDeals: activeOption === "team-deals",
    };
    let response = await getDeals(data);
    if (user && !user?.completed_intro_tour && tourStep > 0) {
      setTourIsOpen(true);
    }
    // Set deals data to state
    setTotalPages(response?.total_pages);
    setTotalDeals(response?.total_deals);
    console.log("Deals: ", response?.deals);
    setData(response?.deals);
    dispatch(endLoading());
  };

  const deleteDealChoice = async (e: React.MouseEvent<HTMLTableDataCellElement>, deal_id: string) => {
    e.preventDefault();
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this deal? All deal data will be deleted for good."
    );
    if (userConfirmed) {
      dispatch(startLoading());
      let response = await deleteDeal(deal_id);
      dispatch(endLoading());
      window.location.reload();
    } else {
      return;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(user).length !== 0) {
      fetchDealsData();
    }
  }, [activePage, sortBy, direction, user, activeOption]);

  const updateSort = (header: string) => {
    if (header === "Stage") {
      header = "deal_stage";
    } else if (header === "Deal Name") {
      header = "deal_name";
    } else if (header === "Score") {
      header = "total_score";
    } else if (header === "Last Call") {
      header = "created_at";
    } else if (header === "Rep") {
      header = "user";
    }
    if (sortBy === header) {
      setDirection(!direction);
    } else {
      setDirection(true);
    }
    setSortBy(header);
    console.log(`header: ${header}`);
    console.log(`direct: ${direction ? "desc" : "asc"}`);
  };

  const handleDealDetailsNavigation = (deal_id: string) => {
    if (!user?.completed_intro_tour) {
      setTourStep(6);
      setTourIsOpen(false);
      navigate(`/app/deals/${deal_id}`);
    } else {
      navigate(`/app/deals/${deal_id}`);
    }
  };

  const handlePriorityChange = async (
    deal_id: string,
    newPriority: Priority
  ) => {
    try {
      const updatedDeal = await putDeal({ priority: newPriority }, deal_id);
    } catch (error) {
      console.error("Error updating deal priority:", error);
    }
  };

  const handleStageChange = async (deal_id: string, newStage: string) => {
    try {
      setDealStages((prevStages) => ({
        ...prevStages,
        [deal_id]: newStage,
      }));
      const updatedDeal = await putDeal({ deal_stage: newStage }, deal_id);
    } catch (error) {
      console.error("Error updating deal stage:", error);
    }
  };

  const handleDealNameEdit = (dealId: string, currentName: string) => {
    setEditingDealId(dealId);
    setEditedDealName(currentName);
  };

  const handleDealNameSave = async (dealId: string) => {
    try {
      setData((prevData: DealDetails[]) =>
        prevData.map((deal: DealDetails) =>
          deal._id === dealId ? { ...deal, deal_name: editedDealName } : deal
        )
      );
      setEditingDealId(null);
      await putDeal({ deal_name: editedDealName }, dealId);
    } catch (error) {
      console.error("Error updating deal name:", error);
    }
  };

  return (
    <PageWrapper pageTitle="Deals" className="deals-page">
      {/* <SearchWrapper>
        <SearchBar
          value={searchValue}
          updateFunction={setSearchValue}
        ></SearchBar>
      </SearchWrapper> */}
      <ToggleContainer>
        <ToggleBackground activeOption={activeOption} />
        <ToggleButton
          active={activeOption === "my-deals"}
          onClick={() => setActiveOption("my-deals")}
        >
          My Deals
        </ToggleButton>
        <ToggleButton
          active={activeOption === "team-deals"}
          onClick={() => setActiveOption("team-deals")}
        >
          Team Deals
        </ToggleButton>
      </ToggleContainer>
      <DealsWrapper>
        <DealsTable>
          <DealsHeader>
            <tr>
              {tableHeaders.map((header: string, index: number) => (
                <th
                  key={index}
                  className={
                    header === "Score" ? "title-wrapper score" : "title-wrapper"
                  }
                >
                  <p>
                    {header}
                    <div
                      className="filter-wrapper"
                      onClick={() => updateSort(header)}
                    >
                      {header !== "Categories" && header !== "Priority" && (
                        <Filter />
                      )}
                    </div>
                  </p>
                </th>
              ))}
            </tr>
          </DealsHeader>
          <hr />
          <tbody>
            {data ? (
              data?.map((deal: DealDetails, index: number) => {
                return (
                  <>
                    <Deal
                      onClick={(
                        e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
                      ) => {
                        const target = e.target as Element;
                        if (
                          !target.closest(".priority-dropdown") &&
                          !target.closest(".stage-select") &&
                          !target.closest(".name") &&
                          !target.closest(".delete-deal") &&
                          !target.closest(".spin-wrapper")
                        ) {
                          handleDealDetailsNavigation(deal?._id);
                        }
                      }}
                      key={index}
                      className={deal.processing ? "processing" : ""}
                    >
                      <td className="score">
                        <TotalScoreBox score={deal.overall_deal_score / 10}>
                          {Math.round(deal.overall_deal_score)}
                          <span>/100</span>
                        </TotalScoreBox>
                      </td>
                      <td
                        className="name"
                        style={{
                          width: activeOption === "my-deals" ? "21%" : "200px",
                        }}
                      >
                        {editingDealId === deal._id ? (
                          <div>
                            <input
                              value={editedDealName}
                              onChange={(e) =>
                                setEditedDealName(e.target.value)
                              }
                              onBlur={() => handleDealNameSave(deal._id)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleDealNameSave(deal._id);
                                }
                              }}
                              autoFocus
                            />
                          </div>
                        ) : (
                          <div>
                            <span
                              onClick={() =>
                                handleDealNameEdit(deal._id, deal.deal_name)
                              }
                            >
                              {deal.deal_name}
                            </span>
                          </div>
                        )}
                      </td>{" "}
                      {activeOption === "team-deals" && (
                        <td className="lead">
                          <div className="user-wrapper">
                            <div className="pfp">
                              {user.image_url &&
                              deal.user_email === user.email ? (
                                <img src={user.image_url} />
                              ) : user.first_name &&
                                deal.user_id === user.email ? (
                                <div className="letter-wrapper">
                                  {user.first_name[0].toUpperCase()}
                                  {user.last_name[0].toUpperCase()}
                                </div>
                              ) : (
                                <div className="letter-wrapper">
                                  {deal.user_first_name[0].toUpperCase()}
                                  {deal.user_last_name[0].toUpperCase()}
                                </div>
                              )}
                              <div className="text">
                                <p>
                                  {`${deal.user_first_name} ${deal.user_last_name}`}
                                </p>
                                <p className="role">{user.role && user.role}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                      )}
                      <td className="last-call">
                        {moment(deal?.most_recent_call?.call_date).format(
                          "MMM DD, YYYY"
                        )}
                      </td>
                      <td className="stage-select">
                        <select
                          value={dealStages[deal._id] || deal.deal_stage}
                          onChange={(e) =>
                            handleStageChange(deal._id, e.target.value)
                          }
                        >
                          {stages.map((stage: Stage) => (
                            <option key={stage.sortOrder} value={stage.label}>
                              {stage.label}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <div className="scores-wrapper">
                          {deal.highest_scores.length > 0
                            ? deal.highest_scores.map(
                                (category: Category, index: number) => {
                                  return (
                                    <ScoreBox
                                      ref={containerRef}
                                      key={index}
                                      score={category.highest_score}
                                    >
                                      <div
                                        ref={tipRef}
                                        className="floating-advice"
                                        style={{
                                          minHeight: "auto",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div className="triangle"></div>
                                        <p>
                                          <strong>
                                            {category.category_name}
                                          </strong>
                                        </p>
                                        <p>
                                          {category.feedback &&
                                            category.feedback.map(
                                              (feedback: {
                                                feedback_text: string;
                                              }) => (
                                                <p>
                                                  • {feedback.feedback_text}
                                                </p>
                                              )
                                            )}
                                        </p>
                                      </div>
                                      {category.category_name[0]}
                                    </ScoreBox>
                                  );
                                }
                              )
                            : defaultScores.map(
                                (category: CategoryDefault, index: number) => (
                                  <ScoreBox
                                    key={index}
                                    score={category.highest_score}
                                  >
                                    {category.category_name[0]}
                                  </ScoreBox>
                                )
                              )}
                        </div>
                      </td>
                      <td>
                        <PriorityDropdown
                          initialPriority={deal.priority || "Medium"}
                          onPriorityChange={(newPriority) =>
                            handlePriorityChange(deal._id, newPriority)
                          }
                        />
                      </td>
                      <td>
                        <GreenButton
                          className={`${
                            !user?.completed_intro_tour && "deal-details"
                          }`}
                          width={"40px"}
                          height={36}
                          fontSize={12}
                          onClick={() => handleDealDetailsNavigation(deal?._id)}
                        >
                          ➜
                        </GreenButton>
                      </td>
                      {deal.processing ? (
                        <td
                          className="spin-wrapper"
                          style={{ pointerEvents: "auto" }}
                          onClick={(e) => deleteDealChoice(e, deal._id)}
                        >
                          <Refresh />
                        </td>
                      ) : (
                        <td
                          className="svg-wrapper delete-deal"
                          onClick={(e) => deleteDealChoice(e, deal._id)}
                        >
                          <Trash />
                        </td>
                      )}
                    </Deal>
                  </>
                );
              })
            ) : (
              <DealsLoadWrapper></DealsLoadWrapper>
            )}
          </tbody>
        </DealsTable>
      </DealsWrapper>
      <Paginate
        pageValue={10}
        pages={totalPages}
        totalRecords={totalDeals}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </PageWrapper>
  );
};

export default DealsPage;
