import React, { FC, useEffect, useState } from "react";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import {
  Progress,
  ChecklistContainer,
  ChecklistItem,
  Checkbox,
  ItemText,
  ProgressBar,
  ItemContent,
  IntroChecklistWrapper,
  TextWrapper,
  SkeletonText,
  SkeletonButton,
  SkeletonCheckbox,
  SkeletonProgress,
} from "./IntroChecklist.styled";
import { GreenButton } from "shared/shared_styled_comps/components.styled";
import { getIntroChecklist, putUser } from "api/helpers";
import { useDispatch } from "react-redux";
import { setAddDealModal, setVideoTour } from "state/appActions";
import { useNavigate } from "react-router-dom";

interface IntroChecklistProps {}

const IntroChecklist: FC = () => {
  const [checkedItems, setCheckedItems] = useState({
    calendar: false,
    resource: false,
    deal: false,
    crm: false,
    dealCoach: false,
    team_members: false,
    video: true,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [invite, setInvite] = useState(false);

  useEffect(() => {
    const fetchIntroData = async () => {
      const response = await getIntroChecklist();
      if (response) {
        let newData = {
          calendar: response.calendar_integrated || false,
          resource: response.resource_count || false,
          deal: response.deal_count || false,
          crm: response.crm_enabled || false,
          dealCoach: response.used_dealcoach || false,
          team_members: response.team_members || false,
          video: true,
        };
        setCheckedItems((prev) => ({
          ...prev,
          calendar: response.calendar_integrated || false,
          resource: response.resource_count || false,
          deal: response.deal_count || false,
          crm: response.crm_enabled || false,
          dealCoach: response.used_dealcoach || false,
          team_members: response.team_members || false,
        }));
        setIsLoading(false);
        setInvite(response.invites || false);
        const progress =
          (Object.values(newData).filter(Boolean).length /
            Object.keys(newData).length) *
          100;
        const roundedProgress = Math.round(progress);
        putUser({ intro_completion: roundedProgress });
        dispatch({ type: "SET_INTRO_COMPLETION", value: roundedProgress });
      }
    };
    fetchIntroData();
  }, []);

  const toggleItem = (key: keyof typeof checkedItems) => {
    setCheckedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const progress =
    (Object.values(checkedItems).filter(Boolean).length /
      Object.keys(checkedItems).length) *
    100;

  // Add the SkeletonChecklist component
  const SkeletonChecklist = () => (
    <IntroChecklistWrapper style={{ background: "#505357" }}>
      <SkeletonText width="70%" height="24px" margin="0 0 20px 0" />
      <ProgressBar>
        <SkeletonProgress />
      </ProgressBar>
      <SkeletonText width="40%" height="20px" margin="20px 0" />
      <ChecklistContainer>
        {[1, 2, 3, 4, 5, 6, 7].map((item) => (
          <ChecklistItem key={item}>
            <ItemContent>
              <SkeletonCheckbox />
              <TextWrapper>
                <SkeletonText width="200px" height="20px" margin="0 0 8px 0" />
                <SkeletonText width="80%" height="16px" />
              </TextWrapper>
            </ItemContent>
            <SkeletonButton />
          </ChecklistItem>
        ))}
      </ChecklistContainer>
    </IntroChecklistWrapper>
  );

  return (
    <PageWrapper pageTitle="Setup Your Leadoff Account!">
      {isLoading ? (
        <SkeletonChecklist />
      ) : (
        <IntroChecklistWrapper>
          <h4>
            Completing the set up steps below will allow you to get the most
            benefit out of the Leadoff platform.
          </h4>
          <ProgressBar>
            <Progress width={progress} />
          </ProgressBar>
          <h5>
            {Math.round(progress) === 100
              ? `${Math.round(progress)}% completed - great job!`
              : `${Math.round(progress)}% completed - keep it up!`}
          </h5>
          <ChecklistContainer>
            <ChecklistItem>
              <ItemContent>
                <Checkbox checked={checkedItems.video}>
                  {checkedItems.video ? "✓" : ""}
                </Checkbox>
                <TextWrapper>
                  <ItemText checked={checkedItems.video}>
                    Watch tutorial video
                  </ItemText>
                  {!checkedItems.video && (
                    <h4>
                      After Leadoff analyzes your first deal, navigate to the
                      Deal Coach and begin asking it for tactical and strategic
                      advice.
                    </h4>
                  )}
                </TextWrapper>
              </ItemContent>
              <GreenButton
                width={"auto"}
                height={36}
                fontSize={12}
                onClick={() => dispatch(setVideoTour())}
              >
                Watch video
              </GreenButton>
            </ChecklistItem>
            <ChecklistItem>
              <ItemContent>
                <Checkbox checked={checkedItems.calendar}>
                  {checkedItems.calendar ? "✓" : ""}
                </Checkbox>
                <TextWrapper>
                  <ItemText checked={checkedItems.calendar}>
                    Connect your calendar
                  </ItemText>
                  {!checkedItems.calendar && (
                    <h4>
                      Integrating your calendar system allows Leadoff to
                      record call transcripts and automatically analyze your
                      deals as new meetings come in.
                    </h4>
                  )}
                </TextWrapper>
              </ItemContent>
              <GreenButton
                width={"auto"}
                height={36}
                fontSize={12}
                onClick={() =>
                  !checkedItems.calendar
                    ? navigate("/app/settings/integrations")
                    : navigate("/app/calendar")
                }
              >
                {!checkedItems.calendar ? "Connect Calendar" : "View Calendar"}
              </GreenButton>
            </ChecklistItem>

            <ChecklistItem>
              <ItemContent>
                <Checkbox checked={checkedItems.resource}>
                  {checkedItems.resource ? "✓" : ""}
                </Checkbox>
                <TextWrapper>
                  <ItemText checked={checkedItems.resource}>
                    Upload your first Resource
                  </ItemText>
                  {!checkedItems.resource && (
                    <h4>
                      Uploading Resources helps Leadoff contextualize summaries
                      and coaching when calls, emails and other deal data points
                      are analyzed.{" "}
                      <a href="https://www.notion.so/Recording-Sales-Calls-with-Leadoff-ai-2eb957b865604e709b2f8af0932dc2fa#0172bc47c8ab4812ad59b334781f5d21">
                        Learn more about Resources here.
                      </a>
                    </h4>
                  )}
                </TextWrapper>
              </ItemContent>
              <GreenButton
                width={"auto"}
                height={36}
                fontSize={12}
                onClick={() => navigate("/app/resources")}
              >
                {!checkedItems.resource ? "Upload Resource" : "View Resources"}
              </GreenButton>
            </ChecklistItem>

            <ChecklistItem>
              <ItemContent>
                <Checkbox checked={checkedItems.deal}>
                  {checkedItems.deal ? "✓" : ""}
                </Checkbox>
                <TextWrapper>
                  <ItemText checked={checkedItems.deal}>
                    Evaluate your first deal
                  </ItemText>
                  {!checkedItems.deal && (
                    <h4>
                      Calls are automatically added to deals and analyzed after
                      integrating calendar, but you can also upload previously
                      recorded transcripts for analysis. Visit this page for
                      more information about how to upload transcripts from past
                      calls.
                    </h4>
                  )}
                </TextWrapper>
              </ItemContent>
              <GreenButton
                width={"auto"}
                height={36}
                fontSize={12}
                onClick={() =>
                  !checkedItems.deal
                    ? dispatch(setAddDealModal(true))
                    : navigate("/app/deals")
                }
              >
                {!checkedItems.deal ? "Evaluate Deal" : "View Deals"}
              </GreenButton>
            </ChecklistItem>

            <ChecklistItem>
              <ItemContent>
                <Checkbox checked={checkedItems.crm}>
                  {checkedItems.crm ? "✓" : ""}
                </Checkbox>
                <TextWrapper>
                  <ItemText checked={checkedItems.crm}>
                    Connect your CRM
                  </ItemText>
                  {!checkedItems.crm && (
                    <h4>
                      This gives Leadoff the ability to analyze email threads,
                      prospect information and other factors in order to give
                      you the most effective analysis for your deals.
                    </h4>
                  )}
                </TextWrapper>
              </ItemContent>
              <GreenButton
                width={"auto"}
                height={36}
                fontSize={12}
                onClick={() => navigate("/app/settings/integrations")}
              >
                {!checkedItems.crm ? "Connect CRM" : "Manage Integrations"}
              </GreenButton>
            </ChecklistItem>

            <ChecklistItem>
              <ItemContent>
                <Checkbox checked={checkedItems.team_members}>
                  {checkedItems.team_members ? "✓" : ""}
                </Checkbox>
                <TextWrapper>
                  <ItemText checked={checkedItems.team_members}>
                    Invite a teammate
                  </ItemText>
                  {!checkedItems.team_members && (
                    <h4>
                      {invite
                        ? "Invite your sales colleagues to review data together and ensure that all of your team's data lives in one place."
                        : "Almost there! Once your invited teammate joins you'll get credit!"}
                    </h4>
                  )}
                </TextWrapper>
              </ItemContent>
              <GreenButton
                width={"auto"}
                height={36}
                fontSize={12}
                onClick={() => navigate("/app/settings/company-account")}
              >
                {invite
                  ? "Invite Teammate"
                  : "Invite Another Teammate"}
              </GreenButton>
            </ChecklistItem>

            <ChecklistItem>
              <ItemContent>
                <Checkbox checked={checkedItems.dealCoach}>
                  {checkedItems.dealCoach ? "✓" : ""}
                </Checkbox>
                <TextWrapper>
                  <ItemText checked={checkedItems.dealCoach}>
                    Ask Deal Coach to help you
                  </ItemText>
                  {!checkedItems.dealCoach && (
                    <h4>
                      After Leadoff analyzes your first deal, navigate to the
                      Deal Coach and begin asking it for tactical and strategic
                      advice.
                    </h4>
                  )}
                </TextWrapper>
              </ItemContent>
            </ChecklistItem>
          </ChecklistContainer>
        </IntroChecklistWrapper>
      )}
    </PageWrapper>
  );
};

export default IntroChecklist;
