import styled, { keyframes } from "styled-components";
import { colors, scoreToColor } from "colors";

interface DealSummaryWrapperProps {
  showStage: boolean;
}

export const DealSummaryWrapper = styled.div<DealSummaryWrapperProps>`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 101;
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .general-feedback {
    height: ${(props) => (props.showStage ? "61%" : "81%")};
    overflow-y: auto;
    padding-right: 30px;
    padding-bottom: 30px;
    h3 {
      font-size: 30px;
      font-weight: 600;
      margin-top: 30px;
    }
    h5 {
      font-size: 20px;
      margin-bottom: 0;
    }
    p {
      margin-top: 10px;
    }
  }
  hr {
    width: 100%;
    margin-left: 5%;
    border: none;
    height: 1px;
    background-color: ${colors.middleGray};
    margin: 0 0;
    margin-bottom: 30px;
  }
  .all-wrapper {
    overflow-y: auto;
    height: ${(props) => (props.showStage ? "61%" : "81%")};
  }
  .full-wrapper {
    h3 {
      font-size: 20px;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: -30px;
    }
  }
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.div<TabProps>`
  height: 35px;
  padding: 6.65px 13.31px 6.65px 13.31px;
  border-radius: 5px;
  gap: 6.65px;
  background-color: ${colors.darkGray2};
  border: none;
  cursor: pointer;
  box-shadow: ${colors.boxShadow2};
  color: ${colors.white2};
  &:hover {
    background-color: ${colors.white};
    color: ${colors.black};
  }
  ${(props) =>
    props.active &&
    `
      background-color: ${colors.white};
      color: ${colors.black};
    `}
`;

export const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  hr {
    width: 26px;
    transform: rotate(90deg);
    border: none;
    height: 1px;
    background-color: ${colors.grayWhite};
    margin: 0px -6px;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  text-align: left;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .cited-text {
    font-style: italic;
    font-size: 13px;
  }
  .feedback-flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 15px;
    svg {
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
    }
    p {
      margin: 0;
      margin-left: 8px;
      padding: 0;
    }
  }
  .width-wrapper {
    width: 50%;
    border-right: 1px solid ${colors.middleGray};
    padding-right: 8px;
    max-height: 372px;
    overflow: hidden;
    .scroll {
      overflow-y: auto;
      height: 85%;
      padding-right: 5px;
    }
  }
  .width-wrapper-all {
    width: 50%;
    border-right: 1px solid ${colors.middleGray};
    padding-right: 20px;
    .scroll {
      overflow-y: auto;
    }
  }
  .width-wrapper-2 {
    width: 48%;
    padding-right: 15px;
    position: relative;
    padding-bottom: 90px;
    svg {
      height: 25px;
      width: 25px;
      margin-right: 15px;
    }
  }
  .flex {
    display: flex;
    flex-wrap: nowrap;
    h4 {
      width: auto;
      margin-right: 20px;
    }
  }
  p {
    padding-left: 25px;
  }
  .title {
    color: ${colors.mainGreen};
    text-decoration: underline;
    margin-bottom: 20px;
    padding-left: 0;
    font-size: 18px;
  }
  .cited {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.lightGray};
  }
`;

interface ScoreBoxProps {
  score: number;
}

export const ScoreBox = styled.div<ScoreBoxProps>`
  width: 42px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
  span {
    font-size: 10px;
    font-weight: 300;
  }
  ${(props) => {
    let { background, text } = scoreToColor(props.score);
    return `
      background-color: ${background};
      color: ${text};
    `;
  }};
`;

export const ContactsWrapper = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 50px;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 15px;
  height: 140px;
  .name {
    width: 20%;
  }
  .position {
    width: 33%;
  }
  .focus {
    width: 40%;
  }
  .bold {
    font-weight: 600;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    margin-bottom: 10px;
  }
`;

export const ContactDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface StageProps {
  splice: number;
  activeIndex: number;
}

export const StageWrapper = styled.div<StageProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
  margin-bottom: -20px;
  height: 130px;
  .bar-background {
    width: 90%;
    margin: 0 auto;
    background: linear-gradient(darkGray 0%, #4b4d52 100%);
    height: 7px;
    border-radius: 5px;
    position: relative;
  }
  .progress-bar {
    background-color: ${colors.mainGreen};
    height: 5px;
    width: ${(props) => props.splice && `${props.splice * props.activeIndex}%`};
    position: absolute;
    top: 1px;
    max-width: 100%;
  }
  .points-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    top: -3px;
  }
  .point-wrapper {
    width: ${(props) => props.splice && `${props.splice}%;`};
  }
`;

interface PointWrapperProps {
  start: boolean;
  end: boolean;
}
export const PointWrapper = styled.div<PointWrapperProps>`
  display: flex;
  justify-content: center;
  ${(props) => props.start && `justify-content: flex-start;`}
  ${(props) => props.start && `justify-content: flex-end;`}
`;

interface PointProps {
  checked: boolean;
}

export const Point = styled.div<PointProps>`
  display: flex;
  justify-content: space-between;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin-bottom: 15px;
  position: relative;
  ${(props) =>
    props.checked
      ? `
      outline: 5px solid white;
      box-shadow: 0px 4px 4px 0px #00000040;
      background: linear-gradient(180deg, #7CDF4D 0%, #4CABCA 100%);
  `
      : `
      height: 12px;
      width: 12px;
      outline: 8px solid ${colors.black};
      background: ${colors.darkGray2};
  `}
`;

export const Quote = styled.div<PointProps>`
  position: absolute;
  top: 33px;
  left: 20%;
  transform: translate(-50%, 0);
  position: relative;
  margin: 5px;
  display: inline-block;
  font-size: 30px;
  width: 100px;
  height: fit-content;
  font-size: 11px;
  padding: 6px 10px;
  text-align: center;
  color: ${colors.darkGray2};
  font-weight: 500;
  background-color: ${colors.black};
  border-radius: 5px;
  ${(props) =>
    props.checked &&
    `
    color: ${colors.white2};
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 5px;
        padding: 2px; /* control the border thickness */
        background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
      }
  `}

  .point {
    height: 10px;
    width: 10px;
    position: absolute;
    background-color: ${colors.black};
    top: -5px;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
    ${(props) =>
      props.checked &&
      `
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 0px;
        padding: 2px 0 0 2px; /* control the border thickness */
        background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        border-left: none;
        border-bottom: none;
      }
      `}
  }
`;
