import React, {
  FC,
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from "react";
import {
  SideBarWrapper,
  SideBarButton,
  ButtonWrapper,
  BottomButtons,
  AddDealWrapper,
  AddDealModal,
} from "./SideBar.styled";
import { logout } from "auth/authActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LightningRobot from "static/svgs/LightningRobot";
import Dollar from "static/svgs/Dollar";
import Rankings from "static/svgs/Rankings";
import Keyboard from "static/svgs/Keyboard";
import LeadoffRobot from "static/svgs/LeadoffRobot";
import LogoWithText from "static/svgs/LogoWithText";
import Logout from "static/svgs/Logout";
import Settings from "static/svgs/Settings";
import AddBox from "static/svgs/AddBox";
import AddDeal from "components/AddDeal/AddDeal";
import Documents from "static/svgs/Documents";
import { SideBarLink as Link, SideBarBottomLink as BottomLink } from "types";
import { IntroTour, TourStep } from "types";
import Tour from "@reactour/tour";
import { text } from "stream/consumers";
import { callbackify } from "util";
import CallModal from "components/CallModal/CallModal";
import { setCallModal, setAddDealModal } from "state/appActions";
import Calendar from "static/svgs/Calendar";
import UpwardTrend from "static/svgs/UpwardTrend";

interface SideBarProps {
  tourStep: number;
  setTourStep: Dispatch<SetStateAction<number>>;
  setTourIsOpen: Dispatch<SetStateAction<boolean>>;
}

const links: Array<Link> = [
  {
    link: "/get-started",
    text: "Get Started",
    icon: <LightningRobot />,
    className: "dashboard-link",
    tourStep: 0,
  },
  {
    link: "/dashboard",
    text: "Calendar",
    icon: <Calendar />,
    className: "dashboard-link",
    tourStep: 0,
  },
  {
    link: "/deals",
    text: "Deals",
    icon: <Dollar />,
    className: "deals-link",
    tourStep: 4,
  },
  {
    link: "/insights",
    text: "Insights",
    icon: <UpwardTrend />,
    className: "insights-link",
    tourStep: 15,
  },
  // {
  //   link: "/leaderboard",
  //   text: "Leaders",
  //   icon: <Rankings />,
  // },
  {
    link: "/resources",
    text: "Resources",
    icon: <Documents />,
    className: "documents-link",
    tourStep: 15,
  },
];

const SideBar = forwardRef((props: SideBarProps, ref) => {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const location = useLocation();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const showCallModal = useSelector((state: any) => state.app.showCallModal);
  const showAddDealModal = useSelector((state: any) => state.app.showAddDealModal);
  const modalRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<any>({});
  const { pathname } = location;

  // Clears authenticated and local storage
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleClickOutside = (event: React.MouseEvent) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target as Node) &&
      user?.completed_intro_tour
    ) {
      dispatch(setAddDealModal(false))
      dispatch(setCallModal(false))
    }
  };

  const handleAddDeal = () => {
    if (!user?.completed_intro_tour) {
      props.setTourStep(2);
      dispatch(setAddDealModal(true))
    } else {
      dispatch(setAddDealModal(true))
    }
  };

  const bottomLinks: Array<BottomLink> = [
    {
      onClick: () => handleAddDeal(),
      text: "Add Deal",
      icon: <AddBox />,
      className: "add-deal-link",
      tourStep: 1,
    },
    {
      onClick: () => navigate(`app/settings`),
      text: "Settings",
      icon: <Settings />,
      className: ".settings-link",
      tourStep: null,
    },
    {
      onClick: () => handleLogout(),
      text: "Logout",
      icon: <Logout />,
      className: ".logout-link",
      tourStep: null,
    },
  ];

  const handleNavigate = (link: string, step: number | null) => {
    if (!user?.completed_intro_tour && step !== null) {
      props.setTourStep(step + 1);
      navigate(link);
    } else {
      navigate(link);
    }
  };

  if (isAuthenticated && pathname.includes("app")) {
    return (
      <>
        <SideBarWrapper
          ref={ref}
          completedIntroTour={user?.completed_intro_tour}
        >
          <div className="top-buttons">
            <SideBarButton large className="top">
              <div className="small">
                <LeadoffRobot />
              </div>
              <div className="large">
                <LogoWithText />
              </div>
            </SideBarButton>
            {links.map((link: Link, index: number) => {
              return (
                <ButtonWrapper
                  key={index}
                  style={{ position: "relative" }}
                  className={`button-wrapper ${link.className} ${
                    pathname.includes(link.link) ? ` selected` : ""
                  }`}
                  selected={pathname.includes(link.link)}
                >
                  <SideBarButton
                    selected={pathname.includes(link.link)}
                    onClick={() =>
                      handleNavigate(`app${link.link}`, link.tourStep)
                    }
                    disabled={
                      !user?.completed_intro_tour &&
                      props.tourStep !== link.tourStep
                    }
                  >
                    <div className="outer-circle">
                      <div className="inner-circle">{link.icon}</div>
                      <h4 className="hover-show">{link.text}</h4>
                    </div>
                  </SideBarButton>
                </ButtonWrapper>
              );
            })}
          </div>
          <BottomButtons>
            {bottomLinks.map((link: BottomLink, index: number) => (
              <ButtonWrapper
                key={index}
                className={`button-wrapper ${link.className} ${
                  pathname.includes("settings") &&
                  link.text === "Settings" &&
                  ` selected`
                }`}
                selected={
                  pathname.includes("settings") && link.text === "Settings"
                }
              >
                <SideBarButton
                  selected={
                    pathname.includes("settings") && link.text === "Settings"
                  }
                  onClick={link.onClick}
                  disabled={
                    !user?.completed_intro_tour &&
                    props.tourStep !== link.tourStep
                  }
                >
                  <div className="outer-circle">
                    <div className="inner-circle">{link.icon}</div>
                    <h4 className="hover-show">{link.text}</h4>
                  </div>
                </SideBarButton>
              </ButtonWrapper>
            ))}
          </BottomButtons>
          {showAddDealModal && (
            <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
              <AddDealModal ref={modalRef}>
                <AddDeal
                  tourStep={props.tourStep}
                  setTourStep={props.setTourStep}
                  setModal={() => dispatch(setAddDealModal(false))}
                  setTourIsOpen={props.setTourIsOpen}
                />
              </AddDealModal>
            </AddDealWrapper>
          )}
          {showCallModal && (
            <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
              <AddDealModal ref={modalRef}>
                <CallModal />
              </AddDealModal>
            </AddDealWrapper>
          )}
        </SideBarWrapper>
      </>
    );
  }

  return <></>;
});

export default SideBar;
